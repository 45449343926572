var lastId,
    topMenu = $(".header__menu"),
    topMenuHeight = topMenu.outerHeight(),
    menuItems = topMenu.find("a"),
scrollItems = menuItems.map(function(){
  var item = $($(this).attr("href"));
	if (item.length) { return item; }
});
menuItems.click(function(e){
  $('.header__menu').removeClass('menu-visible');
  $('.header__trigger').removeClass('menu-visible');
  var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top;
  $('html, body').stop().animate({
    scrollTop: offsetTop
  }, 600);
  e.preventDefault();
});
$(window).scroll(function(){
  var fromTop = $(this).scrollTop()+topMenuHeight;
  var cur = scrollItems.map(function(){
    if ($(this).offset().top < fromTop)
      return this;
  });
  cur = cur[cur.length-1];
  var id = cur && cur.length ? cur[0].id : "";
  if (lastId !== id) {
    lastId = id;
    menuItems
      .parent().removeClass("current")
      .end().filter("[href='#"+id+"']").parent().addClass("current");
  }
});

$(document).ready(function () {

  initStickyHeader();
  initMatchHeight();
  initEventsCarousel();
  initTrigger();

});

function initStickyHeader() {
  var menu = document.querySelector('.header');
	var origOffsetY = menu.offsetTop;
	function scroll () {
		if ($(window).scrollTop() > origOffsetY) {
			$('.header').addClass('scrolling');
		} else {
			$('.header').removeClass('scrolling');
		}
	}
	scroll();
	document.onscroll = scroll;
}

function initMatchHeight() {
  $('.height').matchHeight();
}

function initEventsCarousel() {
  $('.events__carousel').owlCarousel({
		loop: false,
		items: 3,
    margin: 40,
		dots: false,
		nav: true,
    responsive: {
      1401: {
        items: 3,
        margin: 40
      },
      769: {
        items: 3,
        margin: 30
      },
      561: {
        items: 2,
        margin: 30
      },
      0: {
        items: 1,
        margin: 30
      }
    }
  });
}

function initTrigger() {
	$('.header__trigger').on('click',function() {
		$(this).toggleClass('menu-visible');
		$('.header__menu').toggleClass('menu-visible');
	});
}
